import { useStore } from "~/store";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const store = useStore();
  const nuxtApp = useNuxtApp();
  const { $toast } = nuxtApp;

  // Check Compliance Status
  if (import.meta.client) {
    if (store) {
      if (store.user === null) {
        sessionStorage.clear();
        store.$reset();
        navigateTo("/login");
        return;
      }

      if (Object.keys(store?.active_school ?? []).length === 0) {
        return navigateTo("/dashboard/onboarding");
      } else {
        const whiteList = [
          "/dashboard",
          "/dashboard/settings",
          "dashboard/settings/compliance",
        ];

        if (whiteList.includes(to.path)) {
          return;
        } else if (store?.active_school?.compliance?.status === "approved") {
          return;
        } else if (store?.active_school?.compliance.status === "in-review") {
          $toast.error(
            "Compliance is currently under review. You will receive email notifications regarding its status."
          );
          return navigateTo("/dashboard/settings/compliance");
        } else if (store?.active_school?.compliance.status === "rejected") {
          $toast.error(
            "Your compliance information was rejected. Please review the details and resubmit for review."
          );
          return navigateTo("/dashboard/settings/compliance");
        } else if (
          store?.active_school?.compliance.status !== "approved" &&
          to.path !== "/dashboard/settings/compliance"
        ) {
          $toast.error(
            "Please ensure all compliance information is filled out before proceeding."
          );
          return navigateTo("/dashboard/settings/compliance");
        } else {
          return;
        }
      }
    }
  }
});
